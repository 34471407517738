<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-img
            class="mb-3"
            :src="itemData.image ? itemData.image: ''"
          ></v-img>
          <span class="mb-2">{{ itemData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Item Name (En):</span>
              <span class="text--secondary">{{ itemData.name_en }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Item Name (Ar):</span>
              <span class="text--secondary">{{ itemData.name_ar }}</span>
            </v-list-item>

          
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Price:</span>
              <span class="text--secondary">{{ itemData.price }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Points:</span>
              <span class="text--secondary">{{ itemData.points }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Free Points:</span>
              <span class="text--secondary">{{ itemData.free_points }}</span>
            </v-list-item>

           

            <v-card-actions class="justify-center mt-3">
              <v-btn
                color="primary"
                class="me-3"
                @click="isBioDialogOpen = !isBioDialogOpen"
              >
                Edit
              </v-btn>
              <!-- <v-btn
                color="error"
                outlined
                @click="removeItem(itemData.id)"
              >
                Delete
              </v-btn> -->
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <package-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :item-data="itemData"
        @refresh="updateValue($event)"
      ></package-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import PackageBioEdit from './PackageBioEdit.vue'

export default {
  components: {
    PackageBioEdit,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    removeItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store
              .dispatch('app-packages/deletePackage', id)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                this.$router.push({ path: '/apps/packages/list' })
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  setup() {
    const isBioDialogOpen = ref(false)

    return {
      avatarText,
      kFormatter,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
