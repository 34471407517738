import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPriceInPoints(ctx, price) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/points-by-price',
          data: {
            price,
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPackages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/packages', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPackage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/packages/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addPackage(ctx, data) {
      const bodyFormData = new FormData()

      bodyFormData.append('name_en', data.name_en)
      bodyFormData.append('name_ar', data.name_ar)
      bodyFormData.append('points', data.points)
      bodyFormData.append('price', data.price)
      bodyFormData.append('free_points', data.free_points)
      bodyFormData.append('image', data.image)


      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/packages/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    editPackage(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('package_id', data.id)
      bodyFormData.append('name_en', data.name_en)
      bodyFormData.append('name_ar', data.name_ar)
      bodyFormData.append('points', data.points)
      bodyFormData.append('price', data.price)
      bodyFormData.append('free_points', data.free_points)
      data.image && bodyFormData.append('image', data.image)
  

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/packages/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    deletePackage(_ctx, _id) {
      const data = { package_id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/packages/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
