<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="1250px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="item-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Package Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Package details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          v-model="valid"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="packageDataLocal.name_en"
                :rules="[validators.customeRequired]"
                outlined
                dense
                label="Item Name (En)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="packageDataLocal.name_ar"
                outlined
                dense
                :rules="[validators.required]"
                label="Item Name (Ar)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="packageDataLocal.price"
                outlined
                dense
                :rules="[v => !!v || 'This field is required',v => v >= 0 || 'Price should be greater 0']"
                type="number"
                hide-spin-buttons
                label="Price (JOD)"
                @change="calculatePoints($event)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="packageDataLocal.points"
                outlined
                dense
                :rules="[validators.customeRequired]"
                type="number"
                hide-spin-buttons
                label="Points"
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="packageDataLocal.free_points"
                outlined
                dense
                :rules="[v => v >= 0 || 'Free Points should be greater 0']"
                label="Free Points"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
            <v-file-input
                v-model="packageDataLocal.image"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Choose an image"
                outlined
                dense
                label="Upload Image"
                hide-details="auto"
                hint="Image dimensions: min-width: 833px, max-width: 1670px, min-height: 625px, max-height: 1253px, max-file-size: 512kb |dimensions:ratio=4/3"
                class="mb-6"
               
              ></v-file-input>
            </v-col>

                </v-row>
          
   

          <v-col
            cols="12"
            class="d-flex justify-center mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              @click.prevent="onSubmit"
            >
              submit
            </v-btn>

            <v-btn
              outlined
              color="secondary"
              @click.prevent="$emit('update:is-bio-dialog-open',false)"
            >
              Discard
            </v-btn>
          </v-col>
      
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { customeRequired, emailValidator, imageValidator, integerValidator, required } from '@core/utils/validation'
import { mdiDelete, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const packageDataLocal = ref({})

    packageDataLocal.value = props.itemData

    const valid = ref(false)
    const form = ref(null)
    const priceInPoints = ref(0)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetItemData = () => {
      packageDataLocal.value = JSON.parse(JSON.stringify(packageDataLocal))
      resetForm()
    }

    const calculatePoints = e => {
      if (e <= 0) return

      store
        .dispatch('app-packages/getPriceInPoints', e)
        .then(response => {
          priceInPoints.value = response.data.data.price
          packageDataLocal.value.points = response.data.data.price
        })
        .catch(error => console.error(error))
    }
    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        if (packageDataLocal.value.image === props.itemData.image) {
          delete packageDataLocal.value.image
        }
        try {
          await store.dispatch('app-packages/editPackage', packageDataLocal.value)

          Swal.fire('Updated!', 'data has been updated successfully.', 'success')
          emit('refresh', packageDataLocal.value)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          Object.entries(error.data.message).forEach(err => {
            Swal.fire({
              icon: 'error',
              title: `${err[0]}`,
              text: `${err[1][0]}`,
              footer:
                'Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 512kb',
            })
          })
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        packageDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
      },
    )

    return {
      packageDataLocal,
      form,
      valid,
      resetItemData,
      validate,
      onSubmit,
      calculatePoints,
      priceInPoints,

      // validation
      validators: {
        required,
        emailValidator,
        imageValidator,
        integerValidator,
        customeRequired,
      },
      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
